import { currentJwt, authReady } from '@/utils/auth';

import { ImageMedia } from './helper/image';

import { extractLocalizedId } from './helper/locale';
import i18n from '../i18n';
import { watch, Ref, ref } from 'vue';

export interface ContentBlock {
    component: string
    text?: string
    image?: ImageMedia
}


export class News {

    id: string;
    title: string = '';
    description: string = '';
    date: Date;
    thumbnail: string = '';
    banner: string = '';
    blocks: ContentBlock[] = [];

    constructor(id: string | number, title?: string, thumbnail?: string, banner?: string, description?: string, date?: Date, blocks?: any[]) {
        this.id = String(id);
        if (title) {
            this.title = title;
        }
        if (thumbnail) {
            this.thumbnail = thumbnail;
        }
        if (banner) {
            this.banner = banner;
        }
        if (description) {
            this.description = description;
        }
        this.date = date || new Date();

        if (blocks) {
            this.blocks = blocks.map((item: any) => {
                const component = item.__component.split('bloc.').pop();
                switch (component) {
                    case 'text-bloc':
                        return {
                            component,
                            text: item.textSection
                        };
                    default:
                        return {
                            component,
                            image: new ImageMedia({ ...item.image.data.attributes, id: item.image.data.id })
                        };
                }
            });
        }

    }

    static news : Ref<News[]> = ref([]);

    static async init(): Promise<Ref<News[]>> {

        await authReady;
        const res = await fetch(process.env.VUE_APP_API_URL + '/news-items?populate[image][populate]=*&populate[localizations][populate]=*&populate[contentBloc][populate]=*&locale=' + i18n.global.locale.value, {
            headers: {
                Authorization: 'Bearer ' + currentJwt.value
            }
        });

        const data = (await res.json()).data;
        this.news.value.splice(0);
        for (let i = 0; i < data.length; i++) {
            const image = new ImageMedia({ ...data[i].attributes?.image?.data?.attributes, id: data[i].attributes?.image?.data?.id });
            const c = new News(
                extractLocalizedId(data[i]),
                data[i].attributes.title,
                image.smallestUrl,
                image.largestUrl,
                data[i].attributes.abstract,
                new Date(data[i].attributes.publishedAt),
                data[i].attributes.contentBloc
            );

            this.news.value.push(c);
        }

        return this.news;
    }

}

watch(i18n.global.locale, () => {
    if (News.news.value.length > 0) News.init();
});


export default News;
