
import { defineComponent, Ref, ref } from 'vue';
import Card from '@/components/shared/card.vue';
import Screen from 'quasar/src/plugins/Screen.js';;

interface dataI {
    slide: Ref<any>;
}

export default defineComponent({
    components: {
        Card
    },
    props: ['news'],
    setup(props): dataI {
        return {
            slide: ref(1)
        };
    },
    computed: {
        baseApi(): string | undefined {
            return process.env.VUE_APP_PUBLIC_URL;
        },
        tableLength(): number {
            return Screen.lt.sm ? 1 : Screen.lt.md ? 2 : 3;
        }
    }
});
