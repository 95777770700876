import i18n from '@/i18n';


export const extractLocalizedId = (data: any): string => {
    if (i18n.global.locale.value === i18n.global.fallbackLocale.value) {
        return data.id;
    } else {
        return data.attributes?.localizations?.data?.length ? data.attributes?.localizations.data.find((item: any) => item.attributes.locale === i18n.global.fallbackLocale)?.id : data.id;
    }
};
